import { useEffect } from "react";
import { logError } from "utils/error";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "utils/getterAndSetters";

/**
 * Function to check the deployment timestamp and reload the app if a newer deployment is detected.
 *
 * why: when a creator has an already opened tab,and deploy something on creator tool production
 * and the creator tries to do some action in the same tab post deployment, without refreshing the tab getting chunk failed error
 *
 * This function fetches the latest deployment timestamp from a publicly accessible file,
 * compares it with a timestamp stored in session storage, and reloads the application if the
 * fetched timestamp is more recent than the stored timestamp. This ensures that users always
 * have the latest version of the application without requiring manual refreshes.
 */
const checkDeploymentTimestampAndReload = async () => {
  try {
    // Fetch the version from the public URL
    const response = await fetch(
      // using a dummy timestamp to avoid serving cached value
      `/latest_deployment_date.txt?ts=${Date.now()}`,
      { cache: "no-store" }
    ); // Adding cache: 'no-store' to ensure we always get the latest version
    if (!response.ok) {
      return;
    }
    const timestampText = await response.text();
    const latestTimestamp = parseInt(timestampText.trim(), 10); // Parse the timestamp as an integer
    if (isNaN(latestTimestamp)) {
      return;
    }
    // Get the stored timestamp from session storage
    const storedTimestamp = getSessionStorageItem(
      "latest_deployment_timestamp"
    );
    if (!storedTimestamp) {
      setSessionStorageItem("latest_deployment_timestamp", latestTimestamp);
      return;
    }

    const sessionTimestamp = parseInt(storedTimestamp, 10);
    if (isNaN(sessionTimestamp)) {
      return;
    }

    // Compare timestamps and reload if necessary
    if (latestTimestamp > sessionTimestamp) {
      setSessionStorageItem("latest_deployment_timestamp", latestTimestamp);
      window.location.reload();
    }
  } catch (error) {
    logError({
      error,
      occuredAt: "src/hooks/useDeploymentTimestampCheck.js",
      when: "on checkDeploymentTimestampAndReload",
    });
  }
};

const useDeploymentTimestampCheck = () => {
  useEffect(() => {
    // Call the function immediately on mount
    checkDeploymentTimestampAndReload();

    // Set an interval to call the function every hour (3600000 milliseconds)
    const intervalId = setInterval(
      checkDeploymentTimestampAndReload,
      1000 * 60 * 60
    );

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);
};

export default useDeploymentTimestampCheck;
